import React from "react";
import SiteGutter from "../SiteGutter";
import SiteGrid from "../SiteGrid";
import BasePage from "../BasePage";
import RichText from "../RichText";
import * as styles from "./styles.module.scss";

export default function CustomPage({ pageContext }) {
  return (
    <BasePage pageContext={pageContext}>
      <SiteGrid>
        <SiteGutter>
          <div className={styles.container}>
            <h1 className={styles.headline}>{pageContext.slices?.headline}</h1>
            <div>
              <RichText
                value={pageContext.slices?.body}
                className={styles.content}
              />
            </div>
          </div>
        </SiteGutter>
      </SiteGrid>
    </BasePage>
  );
}
